import React from 'react'
import { Box } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import { useBlogCategories } from '@helpers-blog'

let SeoValues = {
    title: '',
    siteUrl: '',
    slug: '',
    contentType: 'text/html; charset=UTF-8',
    contentLanguage: 'zh-tw',
    description: '',
    fbAdmins: "jonathan.b.adams",
    ogType: "website",
    ogLocale: "zh_TW",
    ogLocaleAlternate: ["zh_CN", "en_US"]
}

const PageCategories = props => {

    const categories = useBlogCategories()

    SeoValues['title'] = '分類'
    SeoValues['description'] = "Category list for Teacher Sammy's blog"
    SeoValues['siteUrl'] = props.pageContext.siteUrl + '/'
    SeoValues['slug'] = props.uri.replace(/\//g, '')

    return (
        <Layout {...props}>
            <Seo {...SeoValues} />
            <Divider />
            <Stack>
                <Main>
                    <Box sx={{ position: `relative`, zIndex: 3 }}>
                        {/*
                            // Everything is disappearing on mobile. But I'm using this in a page, 
                            //so I don't want that. Get rid of the "none". BUT, it would appear that
                            // the decision to display image + text or just text is done in teh Categories.
                            <Box sx={{ display: [`none`, `block`] }}>
                        */}
                        <Box sx={{ display: [`block`] }}>
                            <Categories
                            categories={categories}
                            variant='vertical'
                            
                            />
                            <Divider />
                        </Box>
                    </Box>
                </Main>

            </Stack>
        </Layout>
    )

}

export default PageCategories