exports.components = {
  "component---packages-blog-gatsby-blog-core-src-templates-blog-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/blog-posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-blog-posts-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.author.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-author-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-tag-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.tag.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-tag-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-post-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-posts-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-about-teacher-sammy-jsx": () => import("./../../../src/pages/about-teacher-sammy.jsx" /* webpackChunkName: "component---src-pages-about-teacher-sammy-jsx" */),
  "component---src-pages-authors-jsx": () => import("./../../../src/pages/authors.jsx" /* webpackChunkName: "component---src-pages-authors-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-class-parkinsons-jsx": () => import("./../../../src/pages/class-parkinsons.jsx" /* webpackChunkName: "component---src-pages-class-parkinsons-jsx" */),
  "component---src-pages-class-parkinsons-zh-jsx": () => import("./../../../src/pages/class-parkinsons-zh.jsx" /* webpackChunkName: "component---src-pages-class-parkinsons-zh-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-everyday-jsx": () => import("./../../../src/pages/everyday.jsx" /* webpackChunkName: "component---src-pages-everyday-jsx" */),
  "component---src-pages-jonathan-testimonials-jsx": () => import("./../../../src/pages/jonathan-testimonials.jsx" /* webpackChunkName: "component---src-pages-jonathan-testimonials-jsx" */),
  "component---src-pages-parkinsons-writing-jsx": () => import("./../../../src/pages/parkinsons-writing.jsx" /* webpackChunkName: "component---src-pages-parkinsons-writing-jsx" */),
  "component---src-pages-parkinsons-writing-zh-jsx": () => import("./../../../src/pages/parkinsons-writing-zh.jsx" /* webpackChunkName: "component---src-pages-parkinsons-writing-zh-jsx" */),
  "component---src-pages-privacy-notice-jsx": () => import("./../../../src/pages/privacy-notice.jsx" /* webpackChunkName: "component---src-pages-privacy-notice-jsx" */),
  "component---src-pages-school-jsx": () => import("./../../../src/pages/school.jsx" /* webpackChunkName: "component---src-pages-school-jsx" */),
  "component---src-pages-teacher-jonathan-jsx": () => import("./../../../src/pages/teacher-jonathan.jsx" /* webpackChunkName: "component---src-pages-teacher-jonathan-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-pages-testimonials-jsx": () => import("./../../../src/pages/testimonials.jsx" /* webpackChunkName: "component---src-pages-testimonials-jsx" */)
}

