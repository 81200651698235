import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "隱私通知"
    }}>{`隱私通知`}</h1>
    <p>{`最後更新：2023/04/13`}</p>
    <p>{`感謝您使用 Teacher Sammy 的英語學習網誌 (`}<a parentName="p" {...{
        "href": "https://www.teachersammy.com/"
      }}>{`https://www.teachersammy.com/`}</a>{` )。本隱私通知將解釋我們如何收集、使用和披露您的個人資訊。請仔細閱讀本通知以了解我們的做法。`}</p>
    <h2 {...{
      "id": "我們收集的資訊"
    }}>{`我們收集的資訊：`}</h2>
    <ul>
      <li parentName="ul">{`您提供給我們的資訊：當您使用我們的網站時，我們會收集您提供給我們的個人資訊，例如您的姓名、電子郵件地址和付款資訊。`}</li>
      <li parentName="ul">{`我們自動收集的資訊：當您使用我們的網站時，我們會自動收集某些資訊，例如您的 IP 位址、設備類型和瀏覽記錄。`}</li>
      <li parentName="ul">{`我們從第三方收集的資訊：我們可能會從第三方收到有關您的資訊，例如社交媒體平台或營銷合作夥伴。`}</li>
    </ul>
    <h2 {...{
      "id": "我們如何使用您的資訊"
    }}>{`我們如何使用您的資訊：`}</h2>
    <ul>
      <li parentName="ul">{`提供並改進我們的網站。`}</li>
      <li parentName="ul">{`與您溝通，例如發送更新和促銷信息。`}</li>
      <li parentName="ul">{`在我們的網站上為您提供個性化體驗。`}</li>
      <li parentName="ul">{`遵守法律義務。`}</li>
    </ul>
    <h2 {...{
      "id": "我們如何分享您的資訊"
    }}>{`我們如何分享您的資訊：`}</h2>
    <ul>
      <li parentName="ul">{`提供給幫助我們運營網站的第三方服務提供商，例如提供網誌內搜尋功能的 Algolia (`}<a parentName="li" {...{
          "href": "https://www.algolia.com/)%E3%80%82"
        }}>{`https://www.algolia.com/)。`}</a></li>
      <li parentName="ul">{`基於營銷目的，提供與我們合作的公司和業務夥伴。`}</li>
      <li parentName="ul">{`根據法律要求，提供執法或其他政府機構。`}</li>
    </ul>
    <h2 {...{
      "id": "我們如何保護您的資訊"
    }}>{`我們如何保護您的資訊：`}</h2>
    <p>{`我們采取適當措施保護您的個人資訊免受未經授權的存取、披露或破壞。我們會維護及管理技術及實體保護措施，以維護您的資訊。`}</p>
    <h2 {...{
      "id": "您的選擇"
    }}>{`您的選擇：`}</h2>
    <p>{`您可以按照這些信息中的說明取消接收我們的促銷信息。您還可以通過聯繫我們 (`}<a parentName="p" {...{
        "href": "mailto:admin@teachersammy.com"
      }}>{`admin@teachersammy.com`}</a>{`) 行使有關您個人信息的其他權利，例如存取、更正或刪除您的信息。`}</p>
    <h2 {...{
      "id": "a-iddata-deletion-requestsa資料刪除請求"
    }}><a id="data-deletion-requests"></a>{`資料刪除請求：`}</h2>
    <p>{`如果您希望我們刪除您在我們網站上提供的個人資料，請通過電子郵件向我們提出請求 (`}<a parentName="p" {...{
        "href": "mailto:admin@teachersammy.com"
      }}>{`admin@teachersammy.com`}</a>{`)。我們將在收到您的請求後 30 天內刪除您的資料，並將通知您刪除已完成。`}</p>
    <h2 {...{
      "id": "隱私政策的更改"
    }}>{`隱私政策的更改：`}</h2>
    <p>{`我們可能會不時更新本隱私政策。若我們更改政策，我們將在網站上通知，並在法律要求的情況下徵得您的同意。`}</p>
    <h2 {...{
      "id": "聯繫我們"
    }}>{`聯繫我們：`}</h2>
    <p>{`若您對我們的隱私政策有任何疑慮，請透過電子郵件 (`}<a parentName="p" {...{
        "href": "mailto:admin@teachersammy.com"
      }}>{`admin@teachersammy.com`}</a>{`) 聯繫我們。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      