import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import PrivacyNotice from "../components/privacy-notice.mdx"

let SeoValues = {
  title: '',
  siteUrl: '',
  slug: '',
  contentType: 'text/html; charset=UTF-8',
  contentLanguage: 'zh-tw',
  description: '',
  fbAdmins: "jonathan.b.adams",
  ogType: "website",
  ogLocale: "zh_TW",
  ogLocaleAlternate: ["zh_CN", "en_US"]
}

const PagePrivacyNotice = props => {
  
  SeoValues['title'] = 'Privacy Policy'
  SeoValues['description'] = ""
  SeoValues['siteUrl'] = props.pageContext.siteUrl + '/'
  SeoValues['slug'] = props.uri.replace(/\//g, '')

  return (
    <Layout {...props}>
      <Seo {...SeoValues} />
      <Divider />
      <Stack>
        <Main>
          <PrivacyNotice />
        </Main>
      </Stack>
    </Layout>
  )
}

export default PagePrivacyNotice
