import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import About from "../components/school.mdx"

let SeoValues = {
  title: '',
  siteUrl: '',
  slug: '',
  contentType: 'text/html; charset=UTF-8',
  contentLanguage: 'zh-tw',
  description: '',
  fbAdmins: "jonathan.b.adams",
  ogType: "website",
  ogLocale: "zh_TW",
  ogLocaleAlternate: ["zh_CN", "en_US"]
}

const PageSchool = props => {
  
  SeoValues['title'] = "Teacher Sammy's Integral English Training"
  SeoValues['description'] = "Information about Teacher Sammy's school, now closed."
  SeoValues['siteUrl'] = props.pageContext.siteUrl + '/'
  SeoValues['slug'] = props.uri.replace(/\//g, '')

  return (
    <Layout {...props}>
      <Seo {...SeoValues} />
      <Divider />
      <Stack>
        <Main>
          <About />
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageSchool