import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "testimonials"
    }}>{`Testimonials`}</h2>
    <hr></hr>
    <p>{`I must truly say that this English class is very different from other English classes. In the past, we learned English in order to pass exams. We never knew how to correctly use it. No teachers taught us that knowing how to use English is more important than getting good grades in exams.`}</p>
    <p>{`In Jonathan’s class, I am able to ask him all kinds of questions. Sometimes he told us some stories and shared useful tools. Sometimes he taught us special and easier skills to pronounce the word and identify the meanings of the word.`}</p>
    <p>{`I wasn’t interested in English. It was very boring to me. But now I do want to know how to use it, and want to know what the differences are. In this class, I can learn more when Jonathan replies to other classmates’ questions.`}</p>
    <p>{`If you don’t have an idea about improving your ability in English, please give yourself a chance to attend Jonathan’s class.`}</p>
    <p><strong parentName="p">{`Celine`}</strong></p>
    <hr></hr>
    <p>{`我是個曾經是上班族，現在是全職媽媽的家庭主婦。從國小開始就學習英文，但是即使是看得懂，聽得懂，但就是一直無法說出口。每當真正需要開口使用英文時，總是無法好好說出完整的句子。和Jonathan老師學習英文後,沒想到竟然有一天能開口用英文表達自己想法，真的是非常的開心。和他學習英文的過程中，也改善了很多自己在發音上的問題。對於在英文上的問題，不論是課堂上或者課後，Jonathan老師都會很有耐心的一一說明。不用死記文法，也能漸漸抓到語感。真的非常開心和幸運，能夠遇到一位這麼認真的英文老師。`}</p>
    <p><strong parentName="p">{`Sabrina`}</strong></p>
    <hr></hr>
    <p>{`跟著Jonathan老師學英文後，
我常常被問是不是出國留學過?`}</p>
    <p>{`已經不是學生，很難死記東西，
老師用的方法非常科學，可以有效的記住英文。
英文發音也是老師教學強項，
加上Jonathan老師非常有耐心，
總是能解釋得學生聽得懂。`}</p>
    <p>{`不是外國人就會教英文，大力推薦Jonathan老師。`}</p>
    <p><strong parentName="p">{`Rita`}</strong></p>
    <hr></hr>
    <p>{`在這裡上課已經超過五年了
是我唯一一次學習英文堅持成功到現在
（之前每次都失敗）
很喜歡Jonathan 細心與用心的教學態度
他總是不厭其煩的解釋細微的差異，訓練我們的語感；小班制的環境我也很喜歡，大家一起學了五年，同學間的感情也很好。
如果你也是自發性的想紮實的加強英文程度，而非短期的考試目的，很推薦你來這邊快樂學英文，上Jonathan 的課！尤其是英文有一定底子的同學，更適合上Jonathan 的1對1課程，保證你可以學到很多！`}</p>
    <p><strong parentName="p">{`Daisy`}</strong></p>
    <hr></hr>
    <p>{`Jonathan老師的教學經驗很豐富，他非常懂我們會卡關的地方，他也會很有耐心地解說給我們聽。`}</p>
    <p>{`不論是發音、文法或是其它的知識，Jonathan老師都有他的一套方式，讓我們在腦中形成連結，產生印象，進而記住。`}</p>
    <p>{`尤其是發音，Jonathan老師可以清楚地說明英文發音和中文發音的差異，這大大幫助我們改變發音的部位和方式。有的單字，只是發音部位錯了，意思可能就差很多，這也是在課堂中慢慢修正的成果。`}</p>
    <p><strong parentName="p">{`Lucy`}</strong></p>
    <hr></hr>
    <p>{`有人說學習一種語言，其實是在學習一個國家的文化，如此才能真正好好地”使用”它。
對我個人來說，學習英文已不再是為了考試，但經歷了求學生涯那麼長一段時間的學習，卻無法得心應手的使用，一直是心裡的缺憾。所以決定再給自己一次機會來面對，不然遺憾永遠是遺憾。
Jonathan常常說”這堂課”其實不算是一個課程，的確，它比較像是一場討論，參與者可以自由提出英語學習上的疑問，不管是困擾已久的觀念，或者是越看越迷糊的用法，都可以透過Jonathan這位擁有多年英語教學經驗的真正母語文化背景者的解說，得到一個自己想了解的答案，而這個解答你一定無法在google上的制式回覆中搜尋得到。除此之外，透過其它人的提問，將會發覺並重新學習先前自己以為已經懂的內容，或是從未意識到是問題的問題。因此，我覺得”這堂課”是個很好的輔助，可以給自己一些動力去練習、表達及使用英文，並從中得到自己想了解的東西。
成年人的學習是一種主動對自己負責的態度，如果只是想單方面從老師那兒得到知識，大可不用浪費時間逼自己加入；但如果想重新燃起那種最原始的好奇心與對學習的渴望，或許可以來試試！`}</p>
    <p><strong parentName="p">{`Jay`}</strong></p>
    <hr></hr>
    <p>{`傳統中式教育是填鴨式，Jonathan的教育方式則是啟發式。古人云：「授人以魚，不如授人以漁」，意思是傳授人知識，不如傳授給人學習知識的方法。`}</p>
    <p>{`老師常鼓勵同學們要多發問，從發問的過程訓練自己擺脫傳統單方面接收老師資訊的填鴨式學習方式，再經由Jonathan耐心且深入淺出的講解，來釐清一些似懂非懂或是模稜兩可的觀念，老師更鼓勵我們從潛移默化中自然而然的使用英文。透過Jonathan的教學，讓我覺得學習英文是一件很快樂的事。`}</p>
    <p><strong parentName="p">{`Mandy`}</strong></p>
    <hr></hr>
    <p>{`學英文的重要性
是我出社會後工作體會到的`}</p>
    <p>{`加入老師的班級我的程度是國中畢業也離開學校有好一陣時間。
記得以前老師的教法是要我們死背課文，在口說方面幾乎是沒有任何練習念出來也不知道對不對，更別說練習寫作了。`}</p>
    <p>{`課程有以下內容`}</p>
    <ol>
      <li parentName="ol">{`發音：老師會教你每個音的口腔、嘴型、舌頭位置美式發音，也會要你念念看，讓我覺得最棒的部分是他確實聽的出來發音不正確的部分進而給每個人各別細節修改的建議 ( 而不是像坊間我遇過的外師只是想著 : 這是份工作，怕學生討厭或其他原因而不糾正學生 ) ，跟著老師要我修正的地方，透過練習我才知道自己原來能發出正確音，他也教會我發音需要每天練習 ( 不強調時間長短，只要每天練習的次數 ) 就能讓對的發音記在腦後。`}</li>
      <li parentName="ol">{`翻譯：是我最喜歡的動腦翻譯課，老師會講解句子的英文邏輯讓我感受和中文表達方式有很大的差異，對我個人來說這樣學英文會有很好的記憶點，不用靠死背來記。`}</li>
      <li parentName="ol">{`模仿：我想這是很多英文程度不錯的人都需要來學習的，課程內容先從最基本的句子教你如何修正你每個字的發音，進階到連音、語氣，訓練你的口說流暢度。`}</li>
      <li parentName="ol">{`寫作：這堂課能讓你對文法了解更有感，老師會透過寫作練習讓你感覺到文法不是死背的東西，而是與我們日常生活相關所需要表達時間來練習文法時態，也讓你知道如何使用句子通順更接近美籍人士使用上的習慣。`}</li>
    </ol>
    <p>{`老師非常有耐心，即使我錯好幾次老師還是會不厭其煩的修正我，以前和老師說話的時候，我常常腦筋一片空白胡言亂語，現在對於講話前會比較有自信能用正確文法和老師對話。我認為遇到老師是一件很幸運的事，因為我確實感受他的用心且耐心幫助想學英文的人。`}</p>
    <p>{`我在35歲給我自己一個禮物就是學習英文，當時也是拖很久才下定決心來上課。給自己找理由，時間只會一直流逝而沒有任何收穫，真心推薦想學好英文的人付諸行動來找teacher Jonathan學習吧!`}</p>
    <p><strong parentName="p">{`Tinker`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      