import React from 'react'
//import { Layout, Stack, Main, Sidebar } from '@layout'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
//import ContactInfo from '@widgets/ContactInfo'
//import Commitment from '@widgets/Commitment'

let SeoValues = {
  title: '',
  siteUrl: '',
  slug: '',
  contentType: 'text/html; charset=UTF-8',
  contentLanguage: 'zh-tw',
  description: '',
  fbAdmins: "jonathan.b.adams",
  ogType: "website",
  ogLocale: "zh_TW",
  ogLocaleAlternate: ["zh_CN", "en_US"]
}

const PageContact = props => {
  
  SeoValues['title'] = '聯絡'
  SeoValues['description'] = "Contact us with questions or comments"
  SeoValues['siteUrl'] = props.pageContext.siteUrl + '/'
  SeoValues['slug'] = props.uri.replace(/\//g, '')

  return (
    <Layout {...props}>
      <Seo {...SeoValues} />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header="聯絡"
            subheader='如果您有任何問題，請告訴我們！'
          />
          <Divider />
          <ContactForm />
        </Main>
        {/*
          <Sidebar>
            <Commitment />
            <Divider />
            <ContactInfo />
          </Sidebar>
        */}
      </Stack>
    </Layout>
  )
}

export default PageContact
