import React from 'react'
import { Link } from "gatsby"
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: t => `
      linear-gradient(
        45deg,
        ${t.colors.alpha} 32.5%,
        ${t.colors.betaDark} 50.5%)
    `,
    color: `beta`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `normal`,
    maxWidth: [`full`, null, `2/3`],
  }
}

const Content = () => (
  <>
    <Divider space={3} />
    <Heading variant='h1' sx={styles.heading}>
    Sammy老師, Teacher Sammy
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
    <div className="mainHant">您好！我是<span className="mainEn">Jonathan</span>，<Link to="/about-teacher-sammy/"><span className="mainEn">Sammy</span>老師</Link>的丈夫及事業伙伴。您可能已得知<span className="mainEn">Sammy</span>老師於<span className="mainEn">2022</span>年去世的消息；為了讓英語學生繼續由她的寫作、課堂材料和其他作品中受益，並作為對她的紀念，我將繼續維護她的部落格，並持續更新<span className="mainEn">Sammy</span>與我之前討論過的線上課程內容與應用程式。請各位繼續使用她的部落格及其他資源，讓<span className="mainEn">Sammy</span>老師的記憶活在我們的心中。如果您覺得她的內容有助益，請按讚並分享，使她不被遺忘，謝謝！
    </div>
    </Heading>
  </>
)

export default Content
