import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Parkinsons from "../components/parkinsons-writing.mdx"

let SeoValues = {
  title: '',
  siteUrl: '',
  slug: '',
  contentType: 'text/html; charset=UTF-8',
  contentLanguage: 'zh-tw',
  description: '',
  fbAdmins: "jonathan.b.adams",
  ogType: "website",
  ogLocale: "zh_TW",
  ogLocaleAlternate: ["zh_CN", "en_US"]
}

const PageParkinsonsWriting = props => 
{
  SeoValues['title'] = "Jonathan's Parkinson's Writing Exercise"
  SeoValues['description'] = "Stimulating English-Targeted Neuroplasticity in Adults"
  SeoValues['siteUrl'] = props.pageContext.siteUrl + '/'
  SeoValues['slug'] = props.uri.replace(/\//g, '')

  return (
    <Layout {...props}>
      <Seo {...SeoValues}/>
      <Divider />
      <Stack>
        <Main>
          <Parkinsons />
        </Main>
      </Stack>
    </Layout>
  )
  }

export default PageParkinsonsWriting