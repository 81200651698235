import React from 'react'
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import TeacherJonathan from "../components/teacher-jonathan-zh.mdx"

let SeoValues = {
  title: '',
  siteUrl: '',
  slug: '',
  contentType: 'text/html; charset=UTF-8',
  contentLanguage: 'zh-tw',
  description: '',
  fbAdmins: "jonathan.b.adams",
  ogType: "website",
  ogLocale: "zh_TW",
  ogLocaleAlternate: ["zh_CN", "en_US"]
}

const PageTeacherJonathan = props => 
{
  SeoValues['title'] = "Teacher Jonathan's Classes"
  SeoValues['description'] = "Learn about Teacher Jonathan's classes and methods."
  SeoValues['siteUrl'] = props.pageContext.siteUrl + '/'
  SeoValues['slug'] = props.uri.replace(/\//g, '')

  return (
    <Layout {...props}>
      <Seo {...SeoValues}/>
      <Divider />
      <Stack>
        <Main>
          <TeacherJonathan />
        </Main>
      </Stack>
    </Layout>
  )
  }

export default PageTeacherJonathan