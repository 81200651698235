import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "sammy老師的傳記"
    }}>{`Sammy老師的傳記`}</h1>
    <h2 {...{
      "id": "教育背景"
    }}>{`教育背景`}</h2>
    <ul>
      <li parentName="ul">{`國立台灣師範大學翻譯研究所碩士班`}</li>
      <li parentName="ul">{`美國印第安納大學布盧明頓分校 教育心理碩士 (1997-1999)`}</li>
      <li parentName="ul">{`淡江大學英美語文研究所碩士班研究 (1995-1997)`}</li>
      <li parentName="ul">{`淡江大學英文系日間部 (1991-1995)`}</li>
      <li parentName="ul">{`北一女中`}</li>
    </ul>
    <h2 {...{
      "id": "工作經驗"
    }}>{`工作經驗`}</h2>
    <ul>
      <li parentName="ul">{`擔任英語教學已有近 26年經驗（1992 年至今，扣除留美的兩年）`}</li>
      <li parentName="ul">{`時代國際英日語中心：2002 年至 2011 年`}</li>
      <li parentName="ul">{`英美語言訓練測驗中心：2001 年至2009，教授多益、全民英檢`}</li>
      <li parentName="ul">{`曾任教公家機關`}
        <ul parentName="li">
          <li parentName="ul">{`安坑國小教師進修`}</li>
          <li parentName="ul">{`中華郵政總局`}</li>
          <li parentName="ul">{`北市交通局`}</li>
          <li parentName="ul">{`藥檢局`}</li>
          <li parentName="ul">{`航警局`}</li>
          <li parentName="ul">{`台灣證券交易所`}</li>
          <li parentName="ul">{`台灣大學行政單位員工進修`}</li>
          <li parentName="ul">{`海巡署`}</li>
          <li parentName="ul">{`國貿局`}</li>
        </ul>
      </li>
      <li parentName="ul">{`曾任教私人企業`}
        <ul parentName="li">
          <li parentName="ul">{`遠東紡織`}</li>
          <li parentName="ul">{`嬌生`}</li>
          <li parentName="ul">{`AMD電腦公司`}</li>
          <li parentName="ul">{`KPMG 安侯建業聯合會計師事務所`}</li>
          <li parentName="ul">{`德國萊茵公司`}</li>
        </ul>
      </li>
      <li parentName="ul">{`曾任教學校：新店崇光女中、中和南山中學專任英語科教師 (1999-2001)`}</li>
    </ul>
    <h2 {...{
      "id": "成績和認證"
    }}>{`成績和認證`}</h2>
    <ul>
      <li parentName="ul">{`新多益 TOEIC：990/990`}</li>
      <li parentName="ul">{`高級全民英檢通過`}</li>
      <li parentName="ul">{`托福 TOEFL：633/670`}</li>
      <li parentName="ul">{`TSE：290/300`}</li>
    </ul>
    <h2 {...{
      "id": "英語教材編寫及製作"
    }}>{`英語教材編寫及製作`}</h2>
    <ul>
      <li parentName="ul">{`EZ TALK 英語雜誌教學光碟之中文講解`}</li>
      <li parentName="ul">{`佳音事業機構全民英檢英語雜誌主編五年 (2003-2007)：負責每月月刊之內文撰寫、全民英檢試題及解析撰寫、 雜誌內文之講解及錄製光碟`}</li>
      <li parentName="ul">{`Live ABC 雜誌教學光碟之中文講解 (2002)`}</li>
      <li parentName="ul">{`長頸鹿總管理處教材總編輯及師訓班講師 (1999-2000)`}</li>
      <li parentName="ul">{`衛視中文台「英語五分鐘」節目撰稿 (1994-1995)`}</li>
    </ul>
    <h2 {...{
      "id": "翻譯工作"
    }}>{`翻譯工作`}</h2>
    <ul>
      <li parentName="ul">{`台北市立美術館特約翻譯 (2008 至今)`}</li>
      <li parentName="ul">{`桂冠、林白出版社翻譯 (1995-1997)`}</li>
      <li parentName="ul">{`HBO 影片翻譯 (2001)`}</li>
    </ul>
    <h2 {...{
      "id": "著作"
    }}>{`著作`}</h2>
    <ul>
      <li parentName="ul">{`新多益 990 聽力攻略`}</li>
      <li parentName="ul">{`新多益 990 閱讀攻略`}</li>
      <li parentName="ul">{`美式流行語寶典 Hip English Expressions`}</li>
      <li parentName="ul">{`字母的異想世界 (一)`}</li>
      <li parentName="ul">{`Joy Junior 佳音初級全民英檢雜誌月刊`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      