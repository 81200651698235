import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "teacher-sammys-integral-english-training"
    }}>{`Teacher Sammy’s Integral English Training`}</h1>
    <h2 {...{
      "id": "更新學校課程的變動"
    }}>{`更新：學校課程的變動`}</h2>
    <p>{`由於Teacher Sammy’s Integral English Training的創辦人Sammy老師已不在我們身邊，因此學校將進行一些變動，但我們仍會繼續專注於在台灣提供優質的英語教育。`}</p>
    <p>{`學校將不再提供團體課程，但教學經驗豐富且專業的導師Jonathan將繼續為中級和高級程度的學生提供一對一的課程。一對一的課程將著重於提升發音、聽力和口語能力，使學生能夠更自信地說流利的英語。`}</p>
    <h3 {...{
      "id": "閱讀過去的資料及內容"
    }}>{`閱讀過去的資料及內容`}</h3>
    <p>{`目前的學校網站可為前學生提供過去的學習資料及內容。由於本網站即將進行重組，這些內容在未來暫時無法閱讀。`}</p>
    <p>{`我們鼓勵所有希望閱讀過去內容的前學生儘快與我們聯繫，我們將竭力協助您找回所需的資料。請通過以下方式與我們聯繫：`}</p>
    <ul>
      <li parentName="ul">{`電子郵件：`}<a parentName="li" {...{
          "href": "mailto:info@teachersammy.com"
        }}>{`info@teachersammy.com`}</a></li>
      <li parentName="ul">{`聯繫方式：`}<a parentName="li" {...{
          "href": "/contact/"
        }}>{`使用我們的聯繫表格。`}</a></li>
    </ul>
    <p>{`感謝您諒解暫時的不便，並持續支持我們。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      