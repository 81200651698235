import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "we-apologize-"
    }}>{`We apologize …`}</h1>
    <h2 {...{
      "id": "this-information-will-not-be-available-until-we-have-finished-transitioning-from-our-old-platform-please-look-for-this-again-when-we-have-teachersammyai-up"
    }}>{`This information will not be available until we have finished transitioning from our old platform. Please look for this again when we have TeacherSammy.AI up!`}</h2>
    <p>{`非常抱歉 - 在我們從舊平台完整轉移至新平台之前，此資訊將無法使用。請在 TeacherSammy.AI 上線時再次查看！`}</p>
    <h3 {...{
      "id": "one-of-the-smart-ways-to-learn-a-language-is-to-make-the-effort-personal"
    }}>{`One of the smart ways to learn a language is to make the effort personal`}</h3>
    <p>{`Spending some of your time learning about things you are interested in, things you enjoy doing, and things that you do and say every day is a great way to stay motivated, and it provides both a narrow context and a collection of vocabulary and sentences ready-made for the learning process.`}</p>
    <p>{`學語言最聰明的方式之一，就是讓它和你的個人生活產生連結。學習以另一種語言說出符合你的興趣、喜好，或每天要表達使用的事物可讓你對這個語言保持學習熱忱；這種方式更可提供為你量身訂做的一套英語字彙和情境。`}</p>
    <h3 {...{
      "id": "here-we-are-presenting-a-collection-of-sentences-that-our-students-use-in-their-daily-lives-along-with-their-english-translations"
    }}>{`Here we are presenting a collection of sentences that our students use in their daily lives, along with their English translations`}</h3>
    <p>{`Use these to start your own personal study effort!`}</p>
    <p>{`我們在此提供學員們在工作及生活上所用到的英文句子，並配合中文對照。快來看看這些日常的實用英文好句，開始你的個人化學習課程！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      