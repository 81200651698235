
import { Layout, Stack, Main } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Parkinsons from "../components/class-parkinsons.mdx"
/** @jsx jsx */
import { jsx } from 'theme-ui';

let SeoValues = {
  title: '',
  siteUrl: '',
  slug: '',
  contentType: 'text/html; charset=UTF-8',
  contentLanguage: 'zh-tw',
  description: '',
  fbAdmins: "jonathan.b.adams",
  ogType: "website",
  ogLocale: "zh_TW",
  ogLocaleAlternate: ["zh_CN", "en_US"]
}

const PageParkinsonsClass = props => 
{
  SeoValues['title'] = "Jonathan's Parkinson's Writing Class"
  SeoValues['description'] = "Unlock the potential of adult neuroplasticity with Jonathan's Parkinson's Writing Class. Designed to stimulate English-targeted brain adaptability, it's the next step in mastering the language."  
  SeoValues['siteUrl'] = props.pageContext.siteUrl + '/'
  SeoValues['slug'] = props.uri.replace(/\//g, '')

  return (
    <Layout {...props}>
      <Seo {...SeoValues}/>
      <Divider />
      <Stack>
        <Main>
          <img 
            src="/class_header_image.jpg" 
            alt="parkinson's class announcement" 
            sx={{
              maxWidth: '1000px',  // ensures the image doesn't stretch out of its container
              height: 'auto',    // maintains the image's aspect ratio
              width: ['100%', '100%', '100%'],
              margin: '0 auto',
              marginBottom: 4,
           }}
           />
          <Parkinsons />
        </Main>
      </Stack>
    </Layout>
  )
  }

export default PageParkinsonsClass