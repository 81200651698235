import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`大家好，我是Jonathan。你是大學生或是已經成年、擁有中高級英語能力的成人嗎？想要輕鬆提升你的所有英語技能嗎？`}</p>
    <p>{`幾年前，我發現了一項研究，這項研究讓我大吃一驚：成人的大腦依然可以改變、適應和成長。成人仍然有神經可塑性。大多數人還認為這只在兒童時期發生。這不是真的。但這是有區別的，而且有訣竅。它需要一些特定的情境讓大腦以正確的方式作出反應，並且它是關於回收神經元而不是創建新的。`}</p>
    <p>{`由於我的學生一直都是成人，而成人總是很忙，我想找到一種方式來幫助他們提高英語，特別是當他們太忙於其他方式學習時。結果就是`}<a parentName="p" {...{
        "href": "/parkinsons-writing-zh/"
      }}>{`Parkinson’s Writing`}</a>{` - 用五分鐘的時間集中精力，讓你的大腦努力地無意識地使用英語。儘管這項運動使用寫作，目標是使您的英語更加完善。通過刺激神經可塑性，它改進了英語的所有方面。我的一名學生發現，經過幾次練習後，她的聆聽技巧大大提高了！如果做得正確，這就是我們所談論的改變。`}</p>
    <p>{`現在，因為這是一個刺激神經可塑性的運動，即使寫作的結果不被糾正，它仍然有效。但是，由教師修正寫作使其成為更有效的學習時間，特別是如果這些更正用英語討論。我已經使用這個練習作為我的課程的一小部分幾年了。它是如此有效，以至於我決定推出專門使用此練習的課程。`}</p>
    <p>{`這是它的運作方式：`}</p>
    <ul>
      <li parentName="ul">{`在課堂上，我會教你如何做這個練習。`}</li>
      <li parentName="ul">{`作為家庭作業，你每天都做Parkinson’s Writing練習。`}</li>
      <li parentName="ul">{`我將在48小時內修正它們。`}</li>
      <li parentName="ul">{`在下一堂課中，我們討論並幫助每個人理解主題、更正以及任何其他重要的理解內容。`}</li>
      <li parentName="ul">{`然後我們在課堂上進行更多的練習，接著是即時修正和討論。`}</li>
      <li parentName="ul">{`課堂參與對這有效地工作很重要。`}</li>
      <li parentName="ul">{`我所有的討論都是用英語進行的，雖然學生之間的討論和使用生成AI工具ChatGPT的澄清可能是用中文進行的。`}</li>
    </ul>
    <p>{`每堂課：`}</p>
    <ul>
      <li parentName="ul">{`使用`}<a parentName="li" {...{
          "href": "https://zoom.us/"
        }}>{`Zoom`}</a>{`在線進行（為初學者提供協助）。`}</li>
      <li parentName="ul">{`需要使用電腦。`}</li>
      <li parentName="ul">{`需要一個GMail帳戶和訪問`}<a parentName="li" {...{
          "href": "https://docs.google.com/"
        }}>{`Google Docs`}</a>{`（初學者提供指南）。`}</li>
      <li parentName="ul">{`有3到5名學生。`}</li>
      <li parentName="ul">{`持續2小時。`}</li>
      <li parentName="ul">{`每4週期/4堂課的費用為4000 TWD，提前支付。`}</li>
    </ul>
    <p>{`現在，關於我們在課堂上使用的語言。我所有的討論都將是用英語進行的。全部。雖然我會用英語指導和討論一切，但學生偶爾可以切換到中文，以便解開我們需要解開的棘手問題。但這裡有一件事：即使很有挑戰性，保持我們用英語進行對話也可以幫助你的大腦更快地適應。就像彎曲肌肉一樣；你使用得越多，它就越強大。因此，預計大量的英語練習，使我們的課程成為你每週深入研究語言的機會。`}</p>
    <p>{`利用Parkinson’s Writing的獨特潛力，搭配專家的指導和反饋。若你準備開啟大腦的適應力，並實質提升你的英語能力，每週二晚上7點至9點，歡迎`}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`加入我們`}</a>{`。名額有限，請儘速報名！如有任何疑問或希望建議其他上課時間，請`}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`與我們聯繫`}</a>{`。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      