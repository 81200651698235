import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi folks, it’s Jonathan. Are you a college student or adult with intermediate to advanced English abilities? Do you want an easy way to improve all your English abilities?  `}</p>
    <p>{`A few years ago, I discovered some research showing something that surprised me: adult brains can still change, adapt, and grow. Adults still have neuroplasticity (神經可塑性). Most people still think this only happens in children. Not true. But it’s different and there’s a trick to it. It takes some specific circumstances to make the brain respond in the right way, and it’s about recycling neurons rather than creating new ones.`}</p>
    <p>{`Since my students have always been adults, and adults are always busy, I wanted a way to help them improve their English, especially when they are too busy to study in other ways. The result is `}<a parentName="p" {...{
        "href": "/parkinsons-writing/"
      }}>{`Parkinson’s Writing`}</a>{` - five minutes of intense focus with your mind trying its hardest to use English unconsciously. While the exercise uses writing, the goal is to make your English better in general. By stimulating neuroplasticity, it improves all aspects of English. One of my students found that her listening skills dramatically improved after just a few sessions! Done properly, that’s the kind of change we’re talking about.`}</p>
    <p>{`Now, because this is a neuroplasticity-stimulating exercise, it works even if the results of the writing are not corrected. But having a teacher correct the writing makes it an even more effective use of study time, especially if those corrections are discussed in English. I have been using this exercise as a small part of my classes for a few years. It is so effective that I have decided to introduce classes that use this exercise exclusively.`}</p>
    <p>{`Here’s how it works:`}</p>
    <ul>
      <li parentName="ul">{`In class, I’ll teach you how to do the exercise.`}</li>
      <li parentName="ul">{`For homework, once a day, you do the Parkinson’s Writing exercise.`}</li>
      <li parentName="ul">{`I’ll correct them within 48 hours.`}</li>
      <li parentName="ul">{`During the next class, we discuss and help everyone understand the subject, the corrections, and anything else that would be important to understand.`}</li>
      <li parentName="ul">{`Then we do more of the exercise in class, followed by live corrections and discussions.`}</li>
      <li parentName="ul">{`Class participation is important for this to work effectively.`}</li>
      <li parentName="ul">{`ALL of my discussions are in English, although discussions between students and clarifications using the generative AI tool ChatGPT might be in Chinese.`}</li>
    </ul>
    <p>{`Each class:`}</p>
    <ul>
      <li parentName="ul">{`is online using `}<a parentName="li" {...{
          "href": "https://zoom.us/"
        }}>{`Zoom`}</a>{` (assistance provided for beginners).`}</li>
      <li parentName="ul">{`requires access to a computer.`}</li>
      <li parentName="ul">{`requires a GMail account and access to `}<a parentName="li" {...{
          "href": "https://docs.google.com/"
        }}>{`Google Docs`}</a>{` (guidance available for first-timers)`}</li>
      <li parentName="ul">{`has between 3 and 5 students.`}</li>
      <li parentName="ul">{`is 2 hours long.`}</li>
      <li parentName="ul">{`costs 4000 TWD for each 4-week period/4 classes, paid in advance.`}</li>
    </ul>
    <p>{`Now, about the language we use in class. All of my discussions will be in English. All of them. While I’ll be guiding and discussing everything in English, students can occasionally switch to Chinese if there’s a tricky point we need to untangle. But here’s the thing: keeping our conversation in English, even if it’s challenging, helps your brain adapt faster. It’s like flexing a muscle; the more you use it, the stronger it gets. So, expect a lot of English practice, making our sessions your weekly deep dive into the language.`}</p>
    <p>{`Harness the unmatched potential of Parkinson’s Writing, coupled with expert guidance and feedback. If you are ready to tap into your brain’s adaptability and witness a tangible improvement in your English skills, `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`join us`}</a>{` every Tuesday from 7 PM - 9 PM. Hurry, limited slots available! If you have questions or suggestions for a different day or time, `}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`reach out`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      