import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "關於-teacher-sammy-的英語部落格"
    }}>{`關於 Teacher Sammy 的英語部落格`}</h1>
    <p>{`歡迎來到 Teacher Sammy 的英語部落格，一個對台灣學習英語者極具價值的網站。這個部落格是由廣受台灣學生敬重的Teacher Sammy 所創立。雖然Teacher Sammy已於 2022 年去世，但她的丈夫 Jonathan 決定擔起經營部落格的任務並繼續與世界分享她在英語教學方面的心血結晶。`}</p>
    <h2 {...{
      "id": "我們的使命"
    }}>{`我們的使命`}</h2>
    <p>{`本部落格的使命是提供台灣及世界各地的英語學習者 Teacher Sammy 的寶貴資源，讓她的名字永遠留在大家的記憶中。我們相信，她對教育的熱情和她在英語領域的專業知識能持續激勵學生，幫助他們在語言學習的道路上取得優異的成績。`}</p>
    <h2 {...{
      "id": "關於-teacher-sammy"
    }}>{`關於 Teacher Sammy`}</h2>
    <p>{`Teacher Sammy 是一位敬業並充滿熱情的英語教育工作者，她深深影響了台灣許多學生的生活；她提供有效的學習方法、吸引人的教材，並致力於幫助學生提升英語程度。她還創立了一所英語學校，遺憾的是學校在她去世後已停止運營（不過Jonathan 仍在持續提供一對一及發音課程）。`}</p>
    <h2 {...{
      "id": "關於部落格"
    }}>{`關於部落格`}</h2>
    <p>{`本部落格包含了各種英語學習資源，包括：`}</p>
    <ul>
      <li parentName="ul">{`文法課程`}</li>
      <li parentName="ul">{`單字片語解析`}</li>
      <li parentName="ul">{`閱讀教材`}</li>
      <li parentName="ul">{`聽力練習`}</li>
      <li parentName="ul">{`會話技巧`}</li>
      <li parentName="ul">{`文化見解`}</li>
    </ul>
    <h2 {...{
      "id": "與我們聯繫"
    }}>{`與我們聯繫`}</h2>
    <p>{`我們邀請您探索本部落格的資源，如果您認為部落格內容很有助益，請與其他有英語學習需求的人分享Teacher Sammy的專業知識。若您有任何疑問、建議或想投稿，請隨時`}<a parentName="p" {...{
        "href": "/contact/"
      }}>{`與我們聯繫`}</a>{`。`}</p>
    <p>{`感謝您加入我們，懷念 Teacher Sammy，並延續她幫助學生實現英語學習目標的遺志。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      