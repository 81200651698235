import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "衷心感謝我們的學生"
    }}>{`衷心感謝我們的學生`}</h1>
    <p>{`親愛的前學生和訪客，`}</p>
    <p>{`我們想對您們多年來為莎米老師的全方位英語培訓提供的熱情推薦、建議和轉介表示衷心的感謝。您們的支持和鼓勵對我們意義重大，並在學校成功的過程中發揮了重要作用。`}</p>
    <p>{`您可能已經知道，由於莎米老師的去世，莎米老師的全方位英語培訓不再營運。鑒於這些情況，我們決定封存學校的推薦信。我們認為重要的是要承認許多學生在與我們共度時光期間的美好經歷。`}</p>
    <p>{`我們知道您可能正在尋找那些真摯的推薦信，但請放心，我們已經將它們保存下來，作為莎米老師對英語教育投入和熱情的見證。儘管它們可能不再公開展示，但它們仍然是我們歷史和回憶中珍貴的一部分。`}</p>
    <p>{`再次感謝您的鼓勵，感謝您讓莎米老師的全方位英語培訓成為您語言學習旅程的一部分。我們希望您在與我們共度時光期間所獲得的知識和技能將繼續惠及您的未來。`}</p>
    <p>{`感激不盡，`}</p>
    <p>{`Jonathan`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      