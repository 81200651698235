import React from 'react'
import { Flex, Box, Divider, Text, Heading } from 'theme-ui'
import { Link } from 'gatsby';

const textStyles = {
  marginBottom: 3,  // Adds space between Text items. Adjust value as needed.
  fontSize: 3,
};

export const PostJonathan = props => (
  <Box>
    <Divider />
    <Box>

      <Heading as="h3">Personalized One-on-One Online English Classes for Adults!</Heading>
      <Text {...props} sx={textStyles}>Want to improve your English but don’t have time for traditional classes? Our one-on-one online sessions are designed to fit your busy schedule, giving you personalized lessons tailored to your needs. Whether you need help with conversation, writing, or specific business English skills, our classes are all about you! Learn at your own pace, get direct feedback from an experienced teacher, and see fast progress without the pressure of a classroom. Start whenever you’re ready, and take your English skills to the next level—all from the comfort of your home!</Text>
      <Text {...props} sx={{ ...textStyles, marginBottom: 0 }}>
        <Link to="/contact/">Contact me now!</Link>
      </Text>



    </Box>
  </Box>
)
