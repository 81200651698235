import React from "react";
import { Layout, Stack, Main } from "@layout";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import CookiePolicy from "../components/cookie-policy.mdx"

let SeoValues = {
  title: '',
  siteUrl: '',
  slug: '',
  contentType: 'text/html; charset=UTF-8',
  contentLanguage: 'zh-tw',
  description: '',
  fbAdmins: "jonathan.b.adams",
  ogType: "website",
  ogLocale: "zh_TW",
  ogLocaleAlternate: ["zh_CN", "en_US"]
}

const PageCookiePolicy = (props) => {
  
  SeoValues['title'] = 'Cookie policy'
  SeoValues['description'] = "Cookie policy"
  SeoValues['siteUrl'] = props.pageContext.siteUrl + '/'
  SeoValues['slug'] = props.uri.replace(/\//g, '')

  return (
    <Layout {...props}>
      <Seo {...SeoValues} />
      <Divider />
      <Stack>
        <Main>
        <CookiePolicy />
        </Main>
      </Stack>
    </Layout>
  )
}

export default PageCookiePolicy;
