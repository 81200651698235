import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "使用條款"
    }}>{`使用條款`}</h1>
    <p>{`瀏覽及使用本網站（`}<a parentName="p" {...{
        "href": "https://www.teachersammy.com/"
      }}>{`https://www.teachersammy.com/`}</a>{` ）即表示您同意遵守以下條款和條件：`}</p>
    <h2 {...{
      "id": "內容使用"
    }}>{`內容使用`}</h2>
    <p>{`本網站上的內容僅供一般信息之用。內容可能隨時更改，恕不另行通知。我們不就本網站上的信息和材料的正確性、及時性、效果、完整性或適用性提供任何擔保或保證。您同意這些信息和材料可能存在不準確或錯誤，並在法律允許的最大範圍內明確排除因此類不正確或錯誤而產生的任何責任。`}</p>
    <p>{`您在本網站上使用任何信息或材料均自行承擔風險，我們對此概不負責。您應自行確保透過本網站獲得的任何產品、服務或信息符合您的特定需求。`}</p>
    <p>{`本網站包含的材料屬於我們或獲得許可使用的。這些材料包括但不限於設計、排版、外觀和圖形。除非符合版權聲明（作為這些條款和條件的一部分），否則禁止重製。`}</p>
    <h2 {...{
      "id": "第三方網站的連結"
    }}>{`第三方網站的連結`}</h2>
    <p>{`本網站可能包含指向第三方網站的連結，這些網站不受我們控制。我們對這些網站的性質、內容和可用性無法控制。連結的包含不一定表示推薦或支持其中的觀點。`}</p>
    <h2 {...{
      "id": "您的責任"
    }}>{`您的責任`}</h2>
    <p>{`您同意僅出於合法目的使用本網站，並以不侵犯他人權利、限制或禁止他人使用本網站的方式利用網站。`}</p>
    <p>{`您同意不以任何，會使本網站損壞、停用、過載或干擾他人使用和本網站的方式利用網站。`}</p>
    <h2 {...{
      "id": "使用條款的更改"
    }}>{`使用條款的更改`}</h2>
    <p>{`我們可能會不時更新這些使用條款。在此類更改後，您繼續使用本網站即表示您接受新的使用條款。`}</p>
    <h2 {...{
      "id": "管轄法律"
    }}>{`管轄法律`}</h2>
    <p>{`這些使用條款及您使用本網站的行為均受美國懷俄明州法律管轄並根據其解釋。因使用條款及您使用本網站而產生的任何爭議應由懷俄明州法院管轄。`}</p>
    <h2 {...{
      "id": "聯繫我們"
    }}>{`聯繫我們`}</h2>
    <p>{`如果您對這些使用條款有任何疑問，請透過以下聯繫方式聯繫我們：`}<a parentName="p" {...{
        "href": "mailto:admin@teachersammy.com"
      }}>{`admin@teachersammy.com`}</a>{`。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      